import Box from "@mui/material/Box";
import { Tooltip } from "@stacklet/ui";
import { nanoid } from "nanoid";

import AwsLogoWhite from "assets/icons/providers/aws-white.svg";
import AwsLogo from "assets/icons/providers/aws.svg";
import AzureLogo from "assets/icons/providers/azure.svg";
import GcpLogo from "assets/icons/providers/gcp.svg";
import KubernetesLogo from "assets/icons/providers/k8s.svg";
import TencentCloudLogo from "assets/icons/providers/tencentcloud.svg";

import type { CloudProvider } from "app/mutations/__generated__/AddAccountGroupMutation.graphql";

export function getProviderIcon(provider: CloudProvider, invert?: boolean) {
  switch (provider) {
    case "AWS":
      if (invert) {
        return AwsLogoWhite;
      } else {
        return AwsLogo;
      }

    case "Azure":
      return AzureLogo;

    case "GCP":
      return GcpLogo;

    case "TencentCloud":
      return TencentCloudLogo;

    case "Kubernetes":
      return KubernetesLogo;

    default:
      return AwsLogo;
  }
}
interface Props {
  provider: CloudProvider;
  invert?: boolean;
}

export function ProviderIcon({ provider = "AWS", invert = false }: Props) {
  const providerIconSrc = getProviderIcon(provider, invert);

  const id = `provider-tooltip-${nanoid()}`;

  return (
    <Box
      sx={{
        display: "flex",
        width: "24px",
      }}
    >
      <Tooltip data-testid="provider-tooltip" id={id} title={provider}>
        <span>
          <img aria-labelledby={id} src={providerIconSrc} width={22} />
        </span>
      </Tooltip>
    </Box>
  );
}
